.pageNotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background: url(https://images.unsplash.com/photo-1541728472741-03e45a58cf88?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032);
    width: 100vw;
    height: 100vh;
}
.pageNotFound h1 {
    color: #fff;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 1.2px;
    font-family: cursive;
    text-align: center;
}

