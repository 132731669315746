.navbar {
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: 10;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .logo {
  display: flex;
  align-items: center;
  color: #fff;
  margin: 1rem;
}
.navbar .logo a {
  cursor: pointer;
}
.navbar .logo a h2 {
  font-size: 35px;
}
.navbar .logo a {
  cursor: pointer;
}
.navbar .logo a img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.navbar .logo .icon {
  font-size: 3rem;
  margin-right: 1rem;
  color: var(--primary-color);
}

.hamburger {
  display: none;
  padding: 1rem;
}

.navbar .nav-menu {
  height: 100%;
  align-items: center;
  display: flex;
  padding: 1rem;
}
.navbar li {
  padding: 1rem;
  font-weight: 600;
  color: #fff;
}

.navbar .icon {
  font-size: 1.4rem;
  color: #fff;
}

.dropdown {
    background-color: rgba(239, 11, 11);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
  visibility: hidden;
  opacity: 0;
  min-width: 15rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0.5rem;
  left: 0;
  display: none;
}
.dropdown li a {
    color: #fff;
    font-size: 17px;
}
.dropdown li {
    padding: 5px;
}
.drop-hover {
    position: relative;
}
.drop-hover:hover > ul,
.dropdown:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    position: absolute;
}
.drop-hover ul {
    clear: both;
    width: 100%;
}

@media screen and (max-width: 940px) {
  .navbar .hamburger {
    display: block;
    z-index: 15;
  }

  .navbar .nav-menu {
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    transition: 0.5s ease-in;
  }

  .navbar .active {
    left: 0;
  }

  .navbar li {
    margin-right: 1rem;
    font-size: 2rem;
  }

  .navbar button {
    font-size: 1.8rem;
  }
  .drop-hover:hover > ul,
.dropdown:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    position: absolute;
    bottom: 20%;
}

.assist-drop {
  display: none;
}
}
