.citizen-assist {
    background-color: rgb(3, 0, 37);
    height: 15vh;
}
.citizen {
    padding: 3.5rem calc((100vw - 1200px) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
}
.citizen_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
}
.citizen-card {
    padding: 20px;
    background-color: rgb(229, 229, 248);
    box-shadow: 0 20px 20px rgba(0,0,0,0.2);
}
.citizen-card h3{
    background-color: red;
    color: #fff;
    padding: 5px;
} 
.citizen-card p {
    margin-top: 1rem;
}

@media only screen and (max-width: 991px) {
    .citizen-assist {
        height: 14vh;
    }
    .citizen {
        padding: 2rem calc((100vw - 1200px) / 2);
    }
    .citizen_container {
        grid-template-columns: 1fr;
    }
    .citizen-card {
        margin: 10px;
    }
}