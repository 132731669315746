.we-assist {
    background-color: rgb(3, 0, 37);
    height: 15vh;
}
.we-flex {
    padding: 8rem calc((100vw - 1200px) / 2);
}
.we-card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1rem;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    padding: 25px;
    border-radius: 20px;
    background-color: rgb(255, 244, 244);
}
.we-card h2 {
    font-size: 1.5rem;
    font-weight: 500;
}
.we-card p {
    letter-spacing: 1.2px;
    font-size: 1rem;
    margin-top: 0.5rem;
    line-height: 30px;
}
.we-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media only screen and (max-width: 991px) {
    .we-card {
        grid-template-columns: 1fr;
        padding: 10px;
        grid-gap: 0.5rem;
        margin: 15px;
    }
    .we-flex {
        padding: 7rem calc((100vw - 1200px) / 2);
    }
}