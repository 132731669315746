.smb_bg {
    background-color: rgb(3, 0, 37);
    height: 15vh;
}
.smb {
    padding: 1rem calc((100vw - 1200px) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
}
.smb_container {
    display: grid;
    grid-template-columns: 1fr;
}
.smb_card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.smb_title {
    padding: 20px;
    background-color: rgb(245, 245, 250);
    box-shadow: 0 20px 20px rgba(0,0,0,0.2);
    margin-top: 15px;
}
.smb_title h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}
.smb_card h3 {
    padding: 20px;
    background-color: #fff;
    border: 0.7px solid red;
    box-shadow: 0 20px 20px rgba(0,0,0,0.2);
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
}
.smb_card p {
    padding: 20px;
    background-color: rgb(245, 245, 250);
    box-shadow: 0 20px 20px rgba(0,0,0,0.2);
}
.smb_card h3 i {
    margin-left: 10px;
    font-size: 25px;
}


@media only screen and (max-width: 991px) {
    .smb_bg {
        height: 14vh;
    }
    .smb_title {
        padding: 20px;
        background-color: rgb(245, 245, 250);
        box-shadow: 0 20px 20px rgba(0,0,0,0.2);
        margin: 15px 10px;
    }
    .smb_title h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    .smb_card {
        margin: 10px;
    }
    .smb {
    padding: 0.5rem calc((100vw - 1200px) / 2);
    }
}